<script lang="ts" setup>
import { ref, computed, watch, getCurrentInstance } from "vue";
import { getUsers, getUsersFilterOptions } from "@/services/api/iam.api";
import useDateTimeFormatter from "@/composables/formatDateTime.js";
import { mdiTrashCanOutline } from "@mdi/js";
import useGrid27 from "@/composables/grid27.js";
import { FilterDataName, mapFilterResult, FilterStatus, mapFilterResponseStatus } from "@/stores/filterdata";
import GridPagination from "@/components/general/GridPagination.vue";

const emits = defineEmits(["remove-relationship"]);

const { formatDateUtcDT } = useDateTimeFormatter();

const { fetchFirstPage, gridData, gridOptions, gridIsLoading, pageNumber, pageSize, pageCount, totalItems, changedOptions, changedPaging } = useGrid27({
  getData: getData,
  fetchDataError: fetchError,
});

const props = defineProps({
  preFilter: {
    type: Array,
    default: () => [],
  },
  addLink: undefined,
  allowRemove: undefined,
  refreshKey: undefined,
});

const filterData = ref([]);
const showOnlyActive = ref(true);

const _headers = [
  { text: "", value: "actions", sortable: false, width: 20 },
  { text: "Naam", value: "name" },
  { text: "Bedrijf/Afdeling", value: "department" },
  { text: "Gebruikersnaam", value: "userName", width: "20%" },
  { text: "E-mail", value: "email", width: "20%" },
  { text: "Laatste inlog", value: "lastLogin" },
  { text: "Actief", value: "isActive", sortable: false, showOnlyActive: true },
  { text: "", value: "delete-link", sortable: false, width: 20, showOnAllowDelete: true },
];

const headers = computed(() => {
  let newHeaders = [];
  //  if (.$listeners && .$listeners["remove-relationship"]) {
  if (props.allowRemove) {
    newHeaders = _headers.filter((x) => !x.showOnAllowDelete || x.showOnAllowDelete == true);
  } else {
    newHeaders = _headers.filter((x) => !x.showOnAllowDelete);
  }
  if (showOnlyActive.value) {
    newHeaders = newHeaders.filter((x) => !x.showOnlyActive || x.showOnlyActive === false);
  }
  return newHeaders;
});

const filters = ref([
  {
    name: "Naam",
    fieldName: "name",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Bedrijf/Afdeling",
    fieldName: "department",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Gebruikersnaam",
    fieldName: "username",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
  {
    name: "Email",
    fieldName: "email",
    type: "search",
    options: [],
    status: FilterStatus.None,
  },
]);

watch(
  () => props.refreshKey,
  () => {
    fetchFirstPage();
  }
);

watch(
  () => props.preFilter,
  () => {
    //console.log("UsersGrid preFilter: ", props.preFilter);
    filterData.value = [...props.preFilter];
  },
  { immediate: true, deep: true }
);

watch(
  () => filterData.value,
  () => {
    //console.log("UserGrid filterData: ", filterData.value);
    fetchFirstPage();
  },
  { deep: true }
);

watch(
  () => showOnlyActive.value,
  () => {
    console.log("UserGrid showOnlyActive: ", showOnlyActive.value);
    fetchFirstPage();
  },
  { immediate: false, deep: true }
);

async function getData(pageNumber, pageSize, sortBy, sortDesc) {
  const pageNumber0 = pageNumber - 1;
  //console.log("UserGrid getData: ", filterData.value);
  const response = await getUsers(pageNumber0, pageSize, sortBy, sortDesc, filterData.value, true, showOnlyActive.value);
  // console.log("UserGrid getData response: ", response);
  return response;
}

async function handleFilterSearch(index, text) {
  //console.log("UserGrid getFilterOptions: ", filters.value[index].fieldName, text);
  let result = [];
  let status = FilterStatus.None;
  switch (filters.value[index].fieldName) {
    case "active":
      result = filters[index].options;
      break;
    default:
      if (text && text.length > 0) {
        const response = await getUsersFilterOptions(filters.value[index].fieldName, text, props.preFilter, showOnlyActive.value);
        console.log("UserGrid handleFilterSearch response: ", response);
        status = mapFilterResponseStatus(response.severity, response.code);
        if (status === FilterStatus.Success) {
          result = response.data.data;
        } else {
          if (status === FilterStatus.Error) {
            proxy.$toaster.error("Ophalen van de data is mislukt! " + response.message);
          }
        }
      }
      break;
  }
  filters.value[index].status = status;
  filters.value[index].options = mapFilterResult(result);
}

function handleFiltersChanged(newFilterData) {
  filterData.value = [...newFilterData];
}

function onRemoveLink(id) {
  emits("remove-relationship", id);
}

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

function fetchError(value) {
  proxy.$toaster.error("Ophalen van de data is mislukt! " + value);
}
</script>

<template>
  <div>
    <v-row class="overview-filters align-items-start">
      <v-col cols="auto">
        <filter-set :name="FilterDataName.User" :filters="filters" :pre-filters="preFilter" @filter-search="handleFilterSearch" @filters-changed27="handleFiltersChanged"></filter-set>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto" class="text-right">
        <v-btn v-if="props.addLink" dark class="primary mt-4" :to="props.addLink">
          <v-icon dark center>mdi-plus</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto" class="text-right">
        <slot name="add-relation"></slot>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-switch v-model="showOnlyActive" label="Alleen actieve gebruikers" hide-details></v-switch>
      </v-col>
    </v-row>

    <v-data-table
      :options.sync="gridOptions"
      :headers="headers"
      hide-default-footer
      :items="gridData"
      @update:options="changedOptions"
      :loading="gridIsLoading"
      :items-per-page="pageSize"
      :server-items-length="totalItems"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn :to="{ name: 'IAM-UserDetails', params: { userId: item.id } }" icon small plain>
          <v-icon small> mdi-table </v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.lastLogin`]="{ item }">
        {{ formatDateUtcDT(item.lastLogin) }}
      </template>

      <template v-slot:[`item.isActive`]="{ item }">
        {{ item.isActive === true ? "Ja" : "Nee" }}
      </template>

      <template v-slot:[`item.delete-link`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn @click="onRemoveLink(item.id)" icon small plain v-on="on">
              <v-icon small>{{ mdiTrashCanOutline }}</v-icon>
            </v-btn>
          </template>
          <span>Verwijder koppeling</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <grid-pagination :pageNumber="pageNumber" :pageSize="pageSize" :pageCount="pageCount" @changedPaging="changedPaging"></grid-pagination>
  </div>
</template>
